let Invoice = function(tokenClient, serviceAddr) {
	let TokenClient = tokenClient
	let ServiceBaseAddress = serviceAddr
	this.InvoiceInfo = function(oranizationId, success, error) {
		var url = ServiceBaseAddress + '/api/InvoiceInfo/FindByOrganization/'+ oranizationId
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
    this.organizationInfo = function(organizationId, success, error) {
		var url = ServiceBaseAddress + '/api/Organization/Childrens/'
        if (organizationId) {
			url += organizationId
		} else {
			url += 0
		}
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.Department = function(organizationId, success, error) {
		var url = ServiceBaseAddress + '/api/Department/Organization/Tree/'
		if (organizationId) {
			url += organizationId
		} else {
			url += 0
		}
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 新增发票信息
	 * @param item
	 * @param success
	 * @param error
	 * @constructor
	 */
	this.InvoiceInfoAdd = function(item, success, error) {
		var url = ServiceBaseAddress + '/api/InvoiceInfo'
			TokenClient.Post(
				url,
				true,
				{ 'Content-Type': 'application/json' },
				item,
				function(data) {
					if (success) {
						var jsonObj = JSON.parse(data)
						success(jsonObj)
					}
				},
				error
			)
	}

	/**
	 * 编辑发票信息
	 * @param item
	 * @param success
	 * @param error
	 * @constructor
	 */
	this.Edit = function (item, success, error) {
		if (item && item.id) {
			var url = ServiceBaseAddress + '/api/InvoiceInfo';
			TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
				function (data) {
					if (success) {
						var jsonObj = JSON.parse(data);
						success(jsonObj);
					}
				}, error);
		}
	}

	/**
	 * 删除发票信息
	 * @param organizationId
	 * @param success
	 * @param error
	 * @constructor
	 */
	this.InvoiceInfoRemove = function(organizationId, success, error) {
		var url =
			ServiceBaseAddress + '/api/InvoiceInfo/?invoiceInfoId=' + organizationId
		TokenClient.Delete(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	/**
	 * 提交发票
	 * @param item
	 * @param success
	 * @param error
	 * @constructor
	 */
	this.SubmitInvoice = function(item, success, error) {
		var url = ServiceBaseAddress + '/api/Invoice'
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			item,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	this.InvoiceList =  function(organizationId, startTime, endTime, keyWord, pageIndex, success, error) {
		var url = ServiceBaseAddress + '/api/Invoice/FindByOrganization'
		if (organizationId) {
			url += `/${organizationId}`;
		}
		if (startTime) {
			url += `/${startTime}`;
		}
		else {
			url += '/%20';
		}
		if (endTime) {
			url += `/${endTime}`;
		}
		else {
			url += '/%20';
		}
		if (keyWord) {
			url += `/${keyWord}`;
		}
		else {
			url += '/%20';
		}
		if (pageIndex) {
			url += `/${pageIndex}`;
		}
		TokenClient.Get(url, true, null, null, function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			}, error
		)
	}

	this.InvoiceDetails = function(invoiceId, success, error) {
		var url = ServiceBaseAddress + '/api/InvoiceInfo/Detial/'+ invoiceId
		TokenClient.Get(url, true, null, null, function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			}, error
		)
	}
}

export { Invoice }
