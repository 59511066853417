<template>
    <div>
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body" style="height: 100vh;">
                <div class="table-top">
                    <div style="display: flex; justify-content: space-between;">
                        <div style="display: flex;"><h3> 收款账号:</h3><div style="margin-left: 40px;">河南千越医疗科技有限公司</div></div>
                        <div style="display: flex;">应付金额： <div style="color: crimson;">23113</div>元</div>
                    </div>
                    <div style="display: flex;"><h3>收款日期：</h3><div style="margin-left: 30px;">2021-01-21  12：22：34</div></div>
                </div>
                <div style="display: flex; justify-content: space-between; padding: 60px 120px;">
                    <div style="background-color: rgb(95, 201, 201); width: 400px; height: 500px;">
                        <!-- <img src="" alt="" srcset=""> -->
                    </div>
                    <div style="width: 400px; height: 500px; text-align: center;">
                        <h2>使用您的支付宝扫码支付，方便快捷！</h2>
                        <div>二维码将在三分钟后自动刷新</div>
                        <div style="width: 200px;height: 200px; background-color: bisque; transform: translateX(-50%);margin-left: 50%; margin-top: 40px;"></div>
                        <el-button style="margin-top: 40px;"  type="primary">已付款，返回</el-button>
                        <!-- <img src="" alt="" srcset=""> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import bread from "../../../components/common/breadcrumb"
    import {Invoice} from "../../../components/HospitalDomain/Invoice";
    export default {
        name: "Invoice",
        components: {
            bread
        },
        data(){
            var invoice = new Invoice(this.TokenClient, this.Services.Authorization)
            return{
                Invoice: invoice,
                InvoiceData: [],
                setDialog: false,
                DialogTitle: '新增发票信息',
                invoiceForm:{},
                formLabelWidth: '120px',
                organizationData:[]
            }
        },
        mounted() {
            this.getInvoiceInfo();
            // this.getorganizationInfo()
        },
        methods:{
            getInvoiceInfo(){
                let userId = JSON.parse(sessionStorage.getItem('userInfo')).id
                var _this = this
                _this.Invoice.InvoiceInfo(function (data) {
                    _this.InvoiceData = data.data;
                }, function (err) { console.log(err); });
            },
            // getorganizationInfo(){
            //     let organizationId = JSON.parse(sessionStorage.getItem('userInfo')).organizationId
            //     var _this = this
            //     _this.Invoice.organizationInfo(organizationId,function (data) {
            //         _this.organizationData = data.data;
            //     }, function (err) { console.log(err); });
            // },
            Add(){
                this.setDialog = true;
                this.DialogTitle = '新增发票信息';

            },
            EditBtn(){
                this.setDialog = true;
                this.DialogTitle = '编辑发票信息';
            },
            SaveBtn(){
                // InvoiceInfoAdd
                var _this = this
                let userId = JSON.parse(sessionStorage.getItem('userInfo')).id
                _this.invoiceForm.userId = userId
                _this.Invoice.InvoiceInfoAdd(_this.invoiceForm ,function (data) {
                    _this.$message({
                        type: 'success',
                        message: '添加成功!'
                    });
                    _this.setDialog = false
                    _this.getInvoiceInfo()
                }, 
                function (err) { console.log(err); });
                console.log(this.invoiceForm)
            },
            RemoveBtn(e){
                console.log(e)
                var _this = this;
                _this.$confirm('是否删除此信息?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    // type: 'warning'
                    }).then(() => {
                        _this.Invoice.InvoiceInfoRemove(e.id,
                        function (data) {
                            _this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            _this.getInvoiceInfo()
                        },
                        function (err) { console.log(err); });
                    }).catch(() => {
                  
                    });
            },
            CloseBtn(formName){
                this.setDialog = false;
                this.$refs[formName].resetFields();
            }
        }
    }
</script>

<style scoped>
    .dialog-form {
        width: 85%;
    }
    .table-top {
        padding: 20px 0;
        border: 2px solid #ccc;
        border-left: none;
        border-right: none;
    }
</style>
